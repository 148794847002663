#contact-card .contact-card-box-1{
    height: 264px;
}


#contact-card-title{
    font-weight: bolder;
    color: #B12323;
}

#contact-card .card-text{
    font-size: 10pt;
}

#contact-card .card-text-title{
    font-size: 11pt;
    font-weight: bold;
}

#contact-card #contacter{
    padding-bottom: 10px;
}

#contact-card .contact-card-box-3 #phone1{
    text-align: left;
    font-size: 14px;
    color: #353535;
    font-weight: bold;
}

#contact-card .contact-card-box-3 #phone2{
    text-align: left;
    font-size: 14px;
    color: #353535;
    font-weight: bold;
}


#contact-card .contact-card-box-3 #workingHour{
    text-align: left;
    font-size: 14px;
    color: #353535;
}

#contact-card .contact-card-box-3 #email{
    text-align: left;
    font-size: 14px;
    color: #B12323;
    font-weight: bold;
}

#contact-card .contact-card-box-3 #address{
    text-align: left;
    font-size: 14px;
    color: #353535;
}

#contact-card .contact-card-box-3 #address:hover{
    text-align: left;
    font-size: 14px;
    color: #B12323;
    cursor: pointer;
}

.rounded-circle{
    height: 108px !important;
    width: 108px !important;
}