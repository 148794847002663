#first-column-title{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0.55rem;
    line-height: 25px;
}



#footer-top{
    padding-top: 30px;
    padding-bottom: 30px;
}

#fa-footer {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    padding: 10px;
    font-size: 23px;
    text-align: center;
    display: inline-block;
    margin-right: 7px;
    text-decoration: none;
  }
  
  .fa:hover {
      opacity: 0.7;
  }
  
  .fa-facebook {
    background: #3B5998;
    color: white;
  }
  .fa-instagram {
    background: #125688;
    color: white;
  }

#list-second-column-footer{
    list-style-type: none;
    text-align: left;
    padding: 0;
    margin: 0;
    
}

#info-footer{
  color: #353535;
  font-size: 14px;
  line-height: 20px ;
}

#info-footer:hover{
  color: #B12323;
}

#contact-button-foot{
  margin-right: 5px;
  background-color: #B12323;
  border-color: transparent;
}

#contact-button-foot:hover{
  margin-right: 5px;
  background-color: transparent !important;
    color: #B12323;
    text-decoration: none !important;
    border-color: #B12323;
    font-weight: 700;

}

#fa-footer-2{
  color: #B12323;
  margin-right: 10px;
}

#bottom-footer{
  background-color: #B12323;
}



#text-bottom-footer{
  color: white;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 7px;
  
}

#text-bottom-footer p{
  font-size: 10pt;
  margin-bottom: 0;
}

.footerbox-5{
  margin-bottom: 1rem!important;
}

.footerbox-1{
  position: relative;
  width: 100%;
  height: 336px;
  padding-bottom: 3rem;
  padding-right: 40px;
}

/* Small devices (landscape phones, 576px and up) -sm- */
@media (min-width: 576px) {
  .footerbox-1{
    /* width: 80%; */
    max-width: 576px;
  }
}


/* Medium devices (tablets, 768px and up) -md- */
@media (min-width: 992px) {
  .footerbox-1{
    max-width: 100%;
  }
 }

 .footer-logos{
   margin-top: 1.25rem;
   max-width: 220px;
   display: flex;
   flex-wrap: wrap
 }

 #footer-section {
   padding-top: 26px;
 }

 #footer-section .wrap {
   margin-bottom: 40px;
 }

 #list-contact-column-footer{
  list-style: none;
  padding-left: 0;
 }

 #list-contact-column-footer #info-footer{
  font-size: 16px;
  line-height: 25px;
 }

 #footer-section .embed-responsive {
  position: relative;
  display: block;
  width: 90%;
  padding: 0;
  height: 100%;
  overflow: hidden;
}