#myBtn {
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: #353535; /* Set a background color */
    color: white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 10px; /* Some padding */
    border-radius: 10px; /* Rounded corners */
    font-size: 20px; /* Increase font size */
    --animate-duration: 3s;
  }
  
  #myBtn:hover {
    background-color: #353535; /* Add a dark-grey background on hover */
    opacity: 0.75;
  }