#asking-price-title{
    color: #B12323;
    font-weight: bold;
    font-size: 18pt;
}

#askingprice-btn{
    background-color: #B12323;
    border-color: transparent;
    width: 100%;
}

#askingprice-btn:hover{
    background-color: #ee292d;
    border-color: transparent;
    width: 100%;
    font-weight: bold;
}