.feedback-about {
  margin-top: 40pt;
  margin-bottom: 40pt;
}

.feedback-about .card{
    height: 100%;
}

#feedback-title {
  font-size: 40px;
  color: #b12323;
}

#feedback-subtitle {
  font-style: italic;
  font-weight: bold;
}

#customer-name {
  color: #b12323;
  font-size: 12pt;
  font-weight: bold;
}

#customer-workplace {
  color: #b12323;
  font-size: 10pt;
  font-style: italic;
  line-height: 18px;
}

#chitieu {
  color: #b12323;
}

#chitieu2 {
  color: #b12323;
  font-weight: bold;
  font-size: 11pt;
}

/* #customer-feedback-content {
  height: 168px;
} */

.customer-feed-section .card-img {
  position: relative;
  overflow: hidden;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

/* Medium devices (tablets, 768px and up) -md- */
@media (min-width: 768px) {
  .customer-feed-section .card-img {
    width: 100%;
    height: 350px;
  }
}

.customer-feed-section .card-img-top {
  object-fit: cover; 
  object-position: center; /* Center the image within the element */
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.customer-feed-section .card-body {
    position: relative;
}

.customer-feed-section .feedback-wrap {
    /* position: absolute; */
    width: 100%;
}

.customer-feed-section hr {
    margin-top: 0;
    margin-bottom: 0;
}