/* Extra small devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) -sm- */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) -md- */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) -lg- */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) -xl- */
@media (min-width: 1200px) {
}

@media (min-width: 576px) {
  footer .container,
  .info-banner .container {
    max-width: 100%;
    padding: 0 1.25rem;
  }
}

.App {
  text-align: center;
}

p{
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
}

.form-group label {
  font-size: 15px;
}

.form-group .form-control {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  transition: border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out;
  -webkit-transition: border-color 0.35s ease-in-out,
    box-shadow 0.35s ease-in-out;
  -moz-transition: border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out;
  -ms-transition: border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out;
  -o-transition: border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out;
}

.navbar-light {
  background-color: #fff;
}



.raised-1 {
  box-shadow: 0px 0px 10px 0px rgb(0, 0, 0, 0.1);
}

.gradient-1 {
  background: #8e9eab; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #eef2f3,
    #8e9eab
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #eef2f3,
    #8e9eab
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.gradient-2 {
  background: #232526; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #414345,
    #232526
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #414345,
    #232526
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.gradient-3 {
  background: #757f9a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #d7dde8,
    #757f9a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #d7dde8,
    #757f9a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.parallax-window {
  background: transparent;
}

.card,
.card-header,
.card-body {
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.navbar {
  padding: 0;
}

.dropdown-item:active {
  background-color: #b12323;
}

.text-bold {
  font-weight: bold;
}

.bg-black {
  background-color: #1e262c;
}

.root {
  font-family: "Montserrat", sans-serif;
}

.owl-nav {
  text-align: center;
}

.owl-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.owl-stage {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.owl-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto !important;
}

.img-wrap {
  display: block;
  width: 100%;
  height: auto;
}

.content-title {
  color: #b12323;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
}

.content-text {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

#customers .owl-carousel .owl-nav.disabled {
  display: block;
}

#customers .owl-carousel .owl-nav .owl-next,
#customers .owl-carousel .owl-nav .owl-prev {
  background: #000;
  color: white;
  border: 1px solid;
  padding: 0 !important;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
  font-size: 1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* align-self: center; */
}

/* Home css */
#home .btn {
  border-radius: 30px;
  font-size: 0.8rem;
  /* text-transform: uppercase; */
  letter-spacing: 0.2rem;
  padding: 10px 20px;
  text-decoration: none;
}

#home .btn-danger {
  background-color: #b12323;
  font-weight: bold;
  border: 2px solid #b12323;
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -ms-transition: 0.5s ease;
  -o-transition: 0.5s ease;
}

#home .btn-danger:hover {
  background-color: transparent;
  color: #b12323;
}

#home .title-section {
  text-align: center;
  margin: 0 auto 28px;
  padding: 8px 5px;
  width: 100%;
}

#home .title {
  font-size: 38px;
  color: #b12323;
  font-weight: 700;
  text-transform: uppercase;
}

#slide-wrapper {
  position: relative;
  margin-bottom: 0;
}

.slides {
  position: relative;
  background-color: black;
  height: 75vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

#slide-wrapper .slides .video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}

.slides .container {
  position: relative;
  z-index: 2;
}

.slides video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  /*width: auto;
  height: auto;
  */
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
}

.site-padding {
  padding: 5rem 0;
}

#customers .title-section .title {
  /* border-bottom: 2px solid #afafaf; */
  padding-bottom: 0.75rem;
}

#materials .showcase-img {
  /* height: 350px;
  background-size: cover!important; */
  position: relative;
}

#materials h3 {
  color: #ffffff;
}

#materials .image {
  display: block;
  width: 100%;
  height: auto;
}

#materials .showcase-img .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background: rgba(0, 0, 0, 0.7);
}
#materials .showcase-img:hover .overlay {
  opacity: 1;
}

.overlay-text-section {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  padding: 35px;
}

#materials .p-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

#materials .overlay-text-section p{
  font-size: 18px;
}

#feature-services .service-1 {
  padding: 25px 25px 35px 25px;
  background: #b12323;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 300px;
}

#feature-services .service-1 .service-box {
  position: relative;
  height: 100%;
}

#feature-services .service-content-wrap {
  position: relative;
  padding-bottom: 10px;
}

#feature-services .service-1 h3 {
  font-size: 1.4rem;
  line-height: 1.3;
  text-transform: capitalize;
  color: #ffffff;
}

#feature-services .service-1 p {
  font-size: 14px;
  line-height: 1.8;
  color: #ffffff;
}

#feature-services .service-1 .btn {
  text-align: center;
  width: 38px;
  /*font-size: 18px;
  */
  padding: 5px;
  line-height: 0.5rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

#feature-services .wrap-icon {
  font-size: 4rem;
}

#feature-services .wrap-icon .iconfont {
  color: #ffffff;
}

#feature-services .link-wrap {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0px;
}

#feature-services .link {
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
  padding-bottom: 5px;
  margin-bottom: 0px;
  width: fit-content;
  position: relative;
  display: inline-block;
}
#feature-services .link::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background: currentColor;
  bottom: -6px;
  opacity: 0;
  left: 0;
}
#feature-services .link-wrap .link::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 0;
  transition: all 0.5s;
  background: currentColor;
  bottom: -6px;
  left: auto;
  right: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
#feature-services .link-wrap:hover .link::before {
  width: 100%;
  right: auto;
  left: 0;
}

#techniques .card {
  display: -webkit-flex;
  position: relative;
  justify-content: center;
  -webkit-justify-content: center;
  background-color: transparent;
  border: none;
}

#techniques .card .card-wrapper {
  background: white;
  height: 100%;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s;
  -webkit-flex: 1;
}

#techniques .card .card-wrapper .card-img {
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-flex-direction: column;
  -webkit-align-items: center;
  -webkit-justify-content: center;
  width: auto;
  text-align: center;
}

#techniques .card .card-wrapper .card-img .overlay {
  background-color: #ffffff;
  cursor: default;
  transition: opacity 0.3s;
}

#techniques .card .card-wrapper .card-img .overlay-text-section {
  transition: opacity 0.3s;
  opacity: 0;
}

#techniques .card .card-wrapper .card-img:hover .overlay {
  opacity: 0.3;
}

#techniques .card .card-wrapper .card-img:hover .overlay-text-section {
  opacity: 1;
}

#techniques .card .card-wrapper .card-body {
  background-color: #ffffff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#techniques .card .card-wrapper .card-body h4 {
  font-size: 1rem;
  font-weight: 700;
}

#testimonials .card {
  /* border-radius: 20px !important; */
  box-shadow: 2px 2px 10px 0px rgb(0, 0, 0, 0.1);
  /* margin-left: 25px; */
  overflow: hidden;
  width: 100%;
  height: 100%;
}

#testimonials .card .header-wrap {
  display: flex;
  align-items: flex-start;
}

#testimonials .card .header-wrap img {
  width: 70px !important;
  height: 70px !important;
}

#detail-product .product-name-wrap {
  margin-bottom: 15px;
}

#detail-product .product-name {
  text-transform: capitalize;
  font-size: 24px;
  font-weight: 700;
  color: #2a2a2a;
  margin-bottom: 5px;
}

#detail-product .product-price {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bolder;
  color: #ee292d;
}

#detail-product .product-description {
  margin: 20px 0;
  border-top: 1px solid #d5d5d5;
}

#detail-product .product-description p {
  padding-top: 20px;
  /* border-top: 1px dotted #d5d5d5; */
  margin-bottom: 0;
}

#detail-product .product-sizes {
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
  padding: 20px 0;
}

#detail-product .product-sizes h3 {
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 7px;
}

#detail-product .product-sizes .sc-item {
  display: inline-block;
  position: relative;
  margin-right: 5px;
}

#detail-product .product-sizes .sc-item input[type="radio"] {
  visibility: hidden;
  position: absolute;
}

#detail-product .product-sizes .sc-item label {
  display: inline-box;
  text-align: center;
  padding-top: 8.5px;
  font-weight: 500;
  cursor: pointer;
  width: 40px;
  height: 40px;
  font-size: 14px;
  border: 2px solid #414141;
  line-height: normal;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 0;
}

#detail-product .product-sizes .sc-item input[type="radio"]:checked + label {
  border: 2px solid #b12323;
  background-color: #b12323;
  color: #fff;
}

#detail-product .card-area {
  padding: 20px 0;
}

#detail-product .product-count {
  border: 1px solid #ddd;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 12px 15px;
  margin-right: 30px;
}

#detail-product .product-count input {
  border: 0px solid transparent;
  text-align: center;
}

#detail-product .product-count .input-number {
  display: inline-block;
  width: 60px;
  text-align: center;
}

#detail-product .card-area .btn {
  padding: 10px 26px;
  border: unset;
}

#detail-product .card-area .btn-dark {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  background-color: #353535;
  border: 2px solid #353535;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

#detail-product .card-area .btn-dark:hover {
  background-color: #ffffff;
  color: #353535;
  font-weight: bold;
}

#detail-product .product-colors {
  padding: 10px 0;
  /* border-bottom: 1px solid #d5d5d5; */
}

#detail-product .product-colors .color-swap {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: black;
  content: "";
  display: inline-block;
  margin: 0 5px 0 0;
  position: relative;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  transition: 0.5s all ease-in;
  -webkit-transition: 0.5s all ease-in;
  -moz-transition: 0.5s all ease-in;
  -ms-transition: 0.5s all ease-in;
  -o-transition: 0.5s all ease-in;
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
}
/* 
#detail-product .product-colors a.color-swap:hover{
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
} */

#detail-product .product-colors a.color-swap:hover,
#detail-product .product-colors a.color-swap:active,
#detail-product .product-colors a.color-swap:visited,
#detail-product .product-colors a.color-swap:focus{
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

/* #detail-product .product-guide {
  padding: 20px 0;
} */

#detail-product .product-images .img-wrap img {
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -ms-transition: 0.5s ease;
  -o-transition: 0.5s ease;
}

#detail-product .product-images .img-wrap:hover .overlay {
  opacity: 0.8;
}

#detail-product .product-images .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to left, #554346, #45505b) !important;
  opacity: 0;
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -ms-transition: 0.5s ease;
  -o-transition: 0.5s ease;
}

#detail-product .product-images .overlay .overlay-text-section {
  font-size: 32px;
}

.service-header-section .cp-background {
  background-position: 45% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.service-header-section .cp-overlay {
  background-color: #000;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}

.service-header-section {
  overflow: hidden;
  position: relative;
}

.service-header-section .site-padding {
  padding-bottom: 45px;
}

.service-header-section .cp-title {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  color: #c21e1e;
}

.service-header-section .cp-text {
  font-size: 1.25rem;
  line-height: 1.6;
  color: white;
}

.service-header-section .cp-font-style {
  font-family: "Nunito", sans-serif;
}

#company .content-padding {
  padding: 55px 0;
}

#company .wrapper {
  padding: 4rem;
  background-color: #b12323;
  position: relative;
}

#company .content-0 {
  position: relative;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

#company .content-0 .halfbg {
  position: absolute;
  height: 40%;
  content: "";
  width: 100%;
  right: 0rem;
  top: 0rem;
  background: #f8f9fa !important;
}

#company .content-0 .content-text {
  color: white;
  font-size: 16px;
}

#company .content-1 .img-wrapper {
  position: relative;
  padding-left: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  overflow: visible;
  z-index: 1;
}

#company .content-1 .img-wrapper::before {
  position: absolute;
  z-index: -1;
  height: 100%;
  content: "";
  width: calc(100% - 50px);
  left: 0rem;
  top: 0rem;
  background: #b12323;
}

.content-title {
  color: #b12323;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}

.content-text {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

#company .content-2 .content-title {
  text-transform: uppercase;
  font-weight: 700;
}

#company .sub-content {
  margin-bottom: 20px;
}

#company .content-2 .icon-wrapper {
  height: 2.4rem;
  width: 2.4rem;
  margin-right: 2rem;
  display: inline-block;
}

#company .content-2 .icon-wrapper .amp-iconfont {
  font-size: 2.4rem;
}

#company .content-2 h2 {
  font-size: 1.25rem;
  line-height: 1.6;
  font-weight: 900;
  text-transform: capitalize;
}

.raised {
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.178);
}

#branding .content-padding {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

#branding .content-wrapper {
  padding-right: 200px;
}

#branding .content-title {
  font-weight: 700;
  font-size: 34px;
}

#branding .content-text {
  font-size: 1.125rem;
  line-height: 1.6;
}

#branding .contact-us .c-title h1 {
  color: #edf7ff;
}

#branding .contact-us .btn-section {
  text-align: right;
}

#branding .contact-us .btn-section .btn {
  width: fit-content;
  float: right;
  text-transform: uppercase;
  padding: 15px 36px;
  font-weight: 700;
}

#branding .contact-us .btn-section .btn-danger {
  border: 2px solid #dc3545;
  transition: ease 0.5s;
  -webkit-transition: ease 0.5s;
  -moz-transition: ease 0.5s;
  -ms-transition: ease 0.5s;
  -o-transition: ease 0.5s;
}

#branding .contact-us .btn-section .btn-danger:hover {
  background-color: #edf7ff;
  color: #dc3545;
}

#branding .contact-us .btn-section .btn span {
  align-self: center;
  margin-right: 8px;
}

#team.site-padding{
  padding: 70px 0px
}

#team .card {
  border: none;
}

#team .card-wrapper  {
  height: 100%;
  box-shadow: 2.5px 4.33px 30px 0 rgba(64,87,191,0.1);
  transition: all 0.3s;
}

#team .card-title {
  font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    /* color: #b12323; */
}

#team .card-subtitle {
  font-size: 14px;
    line-height: 20px;
    font-weight: normal;
}

#team .card-body {
  padding: 1.75rem 1.25rem;
}

#achievements .card{
  border: none;
  border-radius: 0%;
  height: 100%;
}

#achievements .card-img{
  border-radius: 0% !important;
  max-height: 100%;
}

#achievements .card-body {
  padding: 2rem
}

#achievements .card-title {
  font-size: 24px;
  line-height: 30px;
  text-transform: capitalize;
}

#achievements .achievement-item .card-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

#achievements .card-img-top {
  object-fit: cover;
  object-position: center;
}

#achievements .card-wrapper .overlay{
  transition: 0.5s all ease-in-out;
  transform: scale(0, 1);
  -webkit-transform: scale(0, 1);
  -moz-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  -o-transform: scale(0, 1);
  -webkit-transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  -ms-transition: 0.5s all ease-in-out;
  -o-transition: 0.5s all ease-in-out;
}

#achievements .card-wrapper:hover .overlay{
  opacity: 0.77;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}