/* Small devices (landscape phones, 576px and up) -sm- */
@media (min-width: 576px) {
  .center-1 #p1 {
    color: white;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-shadow: black 1px 1px;
  }

  .center-1 #p2 {
    color: white;
    font-size: 16px;
    font-weight: normal;
    text-shadow: black 1px 1px;
  }
}

/* Medium devices (tablets, 768px and up) -md- */
@media (min-width: 768px) {
  .center-1 #p1 {
    color: white;
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-shadow: black 1px 1px;
  }

  .center-1 #p2 {
    color: white;
    font-size: 18px;
    font-weight: normal;
    text-shadow: black 1px 1px;
  }

  #nhanchinhsachbtn {
    font-size: 18px;
  }
}

/* Large devices (desktops, 992px and up) -lg- */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) -xl- */
@media (min-width: 1200px) {
}
