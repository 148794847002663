.productListPage .product-container #product-name{
    font-size: 14px;
    font-weight: bold;
    color: #B12323;
}

.container-image{
    position: relative;
}

.container-image:hover .overlay{
    opacity: 0.5;
}

.overlay-text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: black;
    cursor: pointer;
  }

  .overlay-text #overlay-title{
      font-size: 10px;
      font-weight: bold;
  }

  .overlay-text #overlay-product-name{
    font-size: 25px;
    font-weight: bold;
}
