.navbar a {
    color: darkred;
    font-weight: lighter;
    font-size: 14px;
}



#contact-button-nav{
    background-color: #B12323 !important;
    color: white;
    text-decoration: none !important;
    border-color: transparent;
    font-size: 14px;

   
    
}

#contact-button-nav:hover{
    background-color: transparent !important;
    color: #B12323;
    text-decoration: none !important;
    border-color: #B12323;
    font-weight: 700;

   
    
}

#aboutus-button-nav{
    text-decoration: none;
}

#cor-button-nav{
    text-decoration: none;
}

.nav-item a{
    font-weight: 600;
}


.nav-link{
  text-decoration: none;
	color: #fff;
	text-transform: uppercase;
	position: relative;
  -webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
    padding: 5px;
    margin: 10 10px;
}

.nav-link:after {
	content: "";
	position: absolute;
	height: 2px;
	background-color: #B12323;
	width: 0;
	left: 50%;
	bottom: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
	transform: translateX(-50%);
	-webkit-transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
  	transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
}

.nav-link:hover{
    color: #B12323 !important;
}

.nav-link:hover:after {
	width: 100%;
}

#hotline-header .col{
    font-size: 9pt !important;
}

#phone-header .col{
    font-size: 15pt !important;
    font-weight: bolder;
}

#top-header{
    background-color: #B12323;
    color: white;
    

    
}

#text-top-header{
    margin-right: auto;
    font-size: 10pt;
    margin-top: 7px;
    margin-bottom: 7px;
}

.dropdown-menu{
    background-color: #ffffff;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    box-shadow: 0 0px 20px rgba(0,0,0,0.2);
    transition: 0.4s all ease-in-out;
    transform: translateY(-2px);
}

.collapse.show{
    padding: 15px 0px;
}

.collapse.show .contact-section{
    margin-top: 10px;
}

/* Large devices (desktops, 992px and up) -lg- */
@media (min-width: 992px) {
    .collapse.show .contact-section{
        margin-top: 0px;
    }
}

.collapse.show .contact-section li {
    margin-bottom: 10px!important;
}
