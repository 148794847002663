.manufacturer{
    margin-top: 40pt;
        margin-bottom: 40pt;
}

#manufacturer-title{
    font-size: 40px;
    color: #B12323;
}

#manufacturer-subtitle{
    font-style: italic;
    
}