#sale .cp-title {
    color: #ffffff;
}

#sale time {
    display: block;
}

#sale .service-header-section .site-padding {
    padding: 80px 0px;
}

.sale-details{
    padding-top: 10px;
    padding-bottom: 10px;
}

.sale-details h5{
    color: #B12323;
    font-weight: bold;
}

.sale-details h1{
    color: #B12323;
    font-weight: bold;
}

.sale-content{
    font-size: 14px;
}

#hr-sale{
    border: 2px solid #B12323;
    width: 50%;
    border-radius: 20px;
}

.text-warning-sale-detail{
    color: #B12323;
}