.contact {
    margin-top: 30px;
    margin-bottom: 30px;
}

.contact .contact-title{
    font-size: 30px;
    margin-bottom: 20px;
    color: #B12323
    ;
}
