
.info-banner{
    background-color: #B12323;
    /* margin-bottom: 30px; */
}

.info-banner-text{
    color: white;
    font-size: 15px;
    margin-right: 20pt;
}

/* Medium devices (tablets, 768px and up) -md- */
@media (min-width: 768px) { 
    .info-banner-text{
    border-left: 0.5pt solid white;
    padding-left: 10pt;
    }
}

.info-banner-text p{
    margin-bottom: 0rem;
}

.info-banner-text a{
    font-weight: bold;

}

.info-banner-text a:hover{
    text-decoration: underline;
    color: white;
}

#info-banner-inside{
    padding-top: 20px;
    padding-bottom: 10px;
}

.linkInfoBanner{
    text-decoration: none;
    color: white;
}