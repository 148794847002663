.coporation {
  margin-bottom: 20px;
}

.coporation-banner {
  position: relative;
  text-align: center;
}

.center-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.center-1 #p1 {
    color: white;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-shadow: black 1px 1px;
  }
  
.center-1 #p2 {
color: white;
font-size: 14px;
font-weight: normal;
text-shadow: black 1px 1px;
}

#nhanchinhsachbtn {
  background-color: #b12323 !important;
  color: white;
  border-color: transparent;
  font-size: 14px;
}

#nhanchinhsachbtn:hover {
  background-color: #ee292d !important;
  color: white;
  border-color: transparent;
}

.coporation .image {
  display: block;
  width: 100%;
}

.coporation .site-padding {
  padding-top: 2rem;
  padding-bottom: 3.25rem;
}

.coporation .coporation-banner {
  margin-bottom: 24px;
}

.coporation-box-1-info h5 {
  font-size: 30px;
  color: #b12323;
  line-height: 40px;
  font-weight: 600;
}

.coporation-box-1-info p {
  font-size: 18px;
  margin-bottom: 2.25rem;
  line-height: 30px;
}

.coporation-box-1-info-2 h5 {
  color: #b12323;
  font-size: 24px;
  line-height: 30px;
}

.coporation-box-1-info-2 p {
  font-size: 14px;
  line-height: 20px;
}

.coporation-box-2 {
  text-align: center;
}

.coporation-box-2 h5 {
  margin: 10pt;
  font-size: 30px;
  color: #b12323;
  margin-bottom: 20pt;
}

.coporation-box-2 .form-group {
  text-align: left;
  font-size: 10pt;
}

#receive-coporate-btn {
  background-color: #b12323;
  border-color: transparent;
  text-align: center;
}

#receive-coporate-btn:hover {
  background-color: #ee292d;
  border-color: transparent;
  text-align: center;
}

#coporation-form {
  padding-bottom: 3rem;
}

.coporation input:focus {
  /* box-shadow: 0px 0px 0px 3px #b1232350 ; */
  border-color: #b12323;
  box-shadow: none;
}

.coporation .btn-primary.focus,
.coporation .btn-primary:focus {
  box-shadow: none;
}

.coporation .btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
