#side-bar h5{
    font-size: 15px;
    font-weight: bold;

}

#side-bar li{
    font-size: 14px;
    
}

#side-bar-content{
    list-style-type: none;
    text-align: left;
    padding: 2;
    margin: 2;
}

#side-bar-content li{
    padding-top: 2px;
    padding-bottom: 2px;
}

#main-content-bar #product-category-name{
    font-size: 30px;
    
    color: #B12323;
}

#breadcump-site{
    list-style-type: none;
    overflow: hidden;
}

.breadcump-site li{
   font-size: 14px;
}

.product-banner{
    position: relative;
    background-size: cover!important;
    padding: 7rem 0;
}

.text-in-product-banner{
    width: 80%;
}

.text-in-product-banner #p1{
    font-size:45px;
    /* padding-bottom: 35px; */
    color: #B12323; 
    padding: 5px 15px; 
    font-weight: 600;
}

.text-in-product-banner #p2{
    font-size: 16px;
    font-weight: bold;
    color: white; 
    padding: 15px ;
    
}

.hr-new{
    border: 1px solid #B12323;

}