.story{
    margin-top: 40pt;
    margin-bottom: 40pt;

}

.content-story #story-title{
    font-size: 38px;
    color: #B12323;
}

.content-story #story-subtitle-h7{
    color: #B12323;
}

.content-story #story-subtitle-h7 b{
    color: black;
}

.content-story li {
    padding-bottom: .75rem;
    line-height: 20px;
}