.culture{
        margin-top: 40pt;
        margin-bottom: 40pt;
}

#culture-title{
        font-size: 38px;
        color: #B12323;
}

#culture-subtitle{
        font-style: italic;
}

#signature-culture{
        font-weight: bold;
        font-style: italic;
}